body {
  margin: 0;
  font-family: 'OpenSansRegular', 'OpenSansBold', 'OpenSansItalic', 'OpenSansBoldItalic',
                'VicidoLS', 'VicidoSans', 'VicidoSansSVG',
                sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
  url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "OpenSansBold";
  src: local("OpenSansBold"),
  url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "OpenSansItalic";
  src: local("OpenSansItalic"),
  url("./assets/fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "OpenSansBoldItalic";
  src: local("OpenSansBoldItalic"),
  url("./assets/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "VicidoSans";
  src: local("VicidoSans-Regular"),
  url("./assets/fonts/VicidoSans-Regular.otf") format("opentype");
}
@font-face {
  font-family: "VicidoSans";
  src: local("VicidoSans-Bold"),
  url("./assets/fonts/VicidoSans-Bold.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "VicidoSans";
  src: local("VicidoSans-Semibold"),
  url("./assets/fonts/VicidoSans-Semibold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "VicidoSans";
  src: local("VicidoSans-Light"),
  url("./assets/fonts/VicidoSans-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "VicidoSansSVG";
  src: local("VicidoSans-Regular-SVG"),
  url("./assets/fonts/VicidoSans-Regular-SVG.otf") format("opentype");
}
@font-face {
  font-family: "VicidoSansSVG";
  src: local("VicidoSans-Bold-SVG"),
  url("./assets/fonts/VicidoSans-Bold-SVG.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "VicidoSansSVG";
  src: local("VicidoSans-Semibold-SVG"),
  url("./assets/fonts/VicidoSans-Semibold-SVG.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "VicidoLS";
  src: local("Vicido-LS-Regular"),
  url("./assets/fonts/VicidoLS-Regular.otf") format("opentype");
}
@font-face {
  font-family: "VicidoLS";
  src: local("Vicido-LS-Bold"),
  url("./assets/fonts/VicidoLS-Bold.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "VicidoLS";
  src: local("Vicido-LS-Semibold"),
  url("./assets/fonts/VicidoLS-Semibold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "VicidoLS";
  src: local("Vicido-LS-Light"),
  url("./assets/fonts/VicidoLS-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "VicidoKT";
  src: local("VicidoKT"),
  url("./assets/fonts/VicidoKT.otf") format("opentype");
}
